import { Icon } from '/features/buildingBlocks/Icon'
import { Link } from '/features/buildingBlocks/Link'
import { NavigationLarge } from '/features/pageOnly/NavigationLarge'
import { NavigationSmall } from '/features/pageOnly/NavigationSmall'

import { useMediaQuery } from '@kaliber/use-media-query'
import { CtaButtonPrimary } from '/features/buildingBlocks/CtaButton'
import { useClientConfig } from '/machinery/ClientConfig'
import { viewportMenu } from '/cssGlobal/media.css'

import styles from './SiteHeader.css'

import iconLogo from '/images/icons/logo.raw.svg'

export function SiteHeader({ disciplines }) {
  const config = useClientConfig()
  const isViewportMd = useMediaQuery(viewportMenu)
  const showDesktopMenu = isViewportMd === true
  const showMobileMenu = isViewportMd === false
  return (
    <header data-x='site-header' className={styles.component}>
      <Link layoutClassName={styles.logoLayout} dataX='link-to-home' href='/'>
        <Icon icon={iconLogo} />
      </Link>
      <div className={styles.inner}>
        {showDesktopMenu && <NavigationLarge layoutClassName={styles.navigationLarge} {...{ disciplines }} />}
        {showMobileMenu && <NavigationSmall {...{ disciplines }} />}
        {showDesktopMenu && (
          <CtaButtonPrimary href={config.careersLink.url} dataX='link-to-klm-careers'>
            {config.careersLink.title}
          </CtaButtonPrimary>
        )}
      </div>
    </header>
  )
}
