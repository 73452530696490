import { v4 as uuid } from 'uuid'
import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'
import { routeMap } from '/routeMap'
import { Icon } from '/features/buildingBlocks/Icon'
import chevronDown from '/images/icons/chevron-down.raw.svg'
import arrowRight from '/images/icons/arrow-right.raw.svg'
import { useLanguage } from '/machinery/I18n'
import { useElementSize }  from '@kaliber/use-element-size'
import { LinkWithoutUnderline, LinkWithUnderline } from '/features/buildingBlocks/Link'
import styles from './NavigationLarge.css'

const DISCIPLINES_SUBMENU_ID = uuid()

export function NavigationLarge({ disciplines, layoutClassName = undefined }) {
  const language = useLanguage()
  const expandedStates = {
    UNEXPANDED: 'unexpanded',
    CLICK: 'byClick',
    MOUSE: 'byMouse'
  }
  const [isExpanded, setIsExpanded] = React.useState(expandedStates.UNEXPANDED)
  const expand = (isExpanded !== expandedStates.UNEXPANDED)

  const elementRef = useOnClickOutside(() => {
    if (expand) setIsExpanded(expandedStates.UNEXPANDED)
  })

  return (
    <nav data-x='site-navigation' className={cx(styles.component, layoutClassName)}>
      <ul className={styles.menu}>
        <li className={styles.menuItem}>
          <LinkWithUnderline href={routeMap.app.about({ language })} dataX='link-in-site-navigation-to-about'>About KLM</LinkWithUnderline>
        </li>
        <li className={styles.menuItem}>
          <LinkWithUnderline href={routeMap.app.events({ language })} dataX='link-in-site-navigation-to-events'>Events</LinkWithUnderline>
        </li>
        <li className={styles.menuItem}>
          <LinkWithUnderline href={routeMap.app.traineeships({ language })} dataX='link-in-site-navigation-to-traineeships'>Traineeships</LinkWithUnderline>
        </li>
        <li
          ref={elementRef}
          onMouseEnter={() => {
            if (isExpanded === expandedStates.CLICK || isExpanded !== expandedStates.UNEXPANDED) return

            setIsExpanded(expandedStates.MOUSE)
          }}
          onMouseLeave={() => {
            if (isExpanded === expandedStates.CLICK || isExpanded === expandedStates.UNEXPANDED) return

            setIsExpanded(expandedStates.UNEXPANDED)
          }}
          className={styles.menuItem}>
          <button
            className={styles.button}
            data-x='click-open-submenu'
            type='button'
            aria-controls={DISCIPLINES_SUBMENU_ID}
            aria-expanded={expand}
            onClick={() => setIsExpanded((isExpanded === expandedStates.CLICK) ? expandedStates.UNEXPANDED : expandedStates.CLICK)}
          >
            <span>Disciplines</span>
            <span className={cx(styles.icon, expand && styles.isActive)}><Icon icon={chevronDown} /></span>
          </button>
          <Submenu
            id={DISCIPLINES_SUBMENU_ID}
            layoutClassName={styles.subMenu}
            items={disciplines}
            {... { disciplines, expand }}
          />
        </li>
      </ul>
    </nav>
  )
}

function Submenu({ id, expand, items, layoutClassName }) {
  const { size: { height }, ref: elementRef } = useElementSize()

  return (
    <div
      className={cx(styles.componentSubmenu, layoutClassName)}
      style={{ height: expand ? height + 'px' : '0px' }}
      aria-hidden={!expand}
      {...{ id }}
    >
      <ul className={styles.submenuItems} ref={elementRef}>
        {items.map((x, i) => {
          return x.slug && (
            <MenuItem
              key={x._id}
              href={determineDocumentPathSync({ document: x, routeMap })}
              title={x.title} {... { i, x }} />
          )
        })}
      </ul>
    </div>
  )
}

function MenuItem({ i, title, href }) {
  return (
    <li key={i} className={styles.componentMenuItem}>
      <LinkWithoutUnderline
        {...{ href }}
        layoutClassName={styles.link}
        dataX='link-in-site-navigation-to-discipline-page'
      >
        <div className={styles.innerLink}>
          <span className={styles.linkIcon}>
            <Icon icon={arrowRight} />
          </span>
          <span className={styles.linkText}>{title}</span>
        </div>
      </LinkWithoutUnderline>
    </li>
  )
}

function useOnClickOutside(onClickOutside) {
  const callback = useEvent(onClickOutside)
  const elementRef = React.useRef(null)

  React.useEffect(
    () => {
      window.addEventListener('click', handleClick, { passive: true })
      return () => window.removeEventListener('click', handleClick)

      function handleClick(e) {
        const li = elementRef.current
        const clickInside = li === e.target || li.contains(e.target)
        if (!clickInside) callback()
      }
    },
    [callback]
  )

  return elementRef
}

function useEvent(fn) {
  const fnRef = React.useRef(null)
  fnRef.current = fn

  return React.useCallback((...args) => fnRef.current(...args), [])
}
