import { routeMap } from '/routeMap'
import { useLanguage } from '/machinery/I18n'
import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'

import { Icon } from '/features/buildingBlocks/Icon'
import { Portal } from '/features/buildingBlocks/Portal'
import { Link } from '/features/buildingBlocks/Link'

import hamburgerIcon from '/images/icons/hamburger.raw.svg'

import styles from './NavigationSmall.css'

export function NavigationSmall({ disciplines }) {
  const [menuOpen, setMenuOpen] = React.useState(false)

  return (
    <nav data-x="site-navigation" className={styles.component}>
      <button
        type="button"
        data-x="toggle-navigation"
        className={styles.button}
        onClick={() => setMenuOpen(true)}
      >
        <Icon icon={hamburgerIcon} />
      </button>
      {menuOpen && (
        <Portal visible={menuOpen} onClose={() => setMenuOpen(false)}>
          <Navigation {...{ disciplines }} />
        </Portal>
      )}
    </nav>
  )
}

function Navigation({ disciplines }) {
  const language = useLanguage()

  return (
    <nav data-x="site-navigation">
      <ul className={styles.componentNavigation}>
        <li className={styles.navigationItem}>
          <Link
            href={routeMap.app.about({ language })}
            dataX="link-in-site-navigation-to-about"
            layoutClassName={styles.navigationLink}
          >
            About KLM
          </Link>
        </li>
        <li className={styles.navigationItem}>
          <Link
            href={routeMap.app.events({ language })}
            dataX="link-in-site-navigation-to-events"
            layoutClassName={styles.navigationLink}
          >
            Events
          </Link>
        </li>
        <li className={styles.navigationItem}>
          <Link
            href={routeMap.app.traineeships({ language })}
            dataX="link-in-site-navigation-to-traineeships"
            layoutClassName={styles.navigationLink}
          >
            Traineeships
          </Link>
        </li>
        <li className={styles.navigationItem}>
          <span className={styles.navigationHeading}>Disciplines</span>
          <SubNav items={disciplines} />
        </li>
      </ul>
    </nav>
  )
}

function SubNav({ items }) {
  return (
    <ul className={styles.componentSubNav}>
      {items.map((x, i) => {
        return (
          x.slug && (
            <li key={x._id} className={styles.subNavigationItem}>
              <Link
                href={determineDocumentPathSync({ document: x, routeMap })}
                dataX="link-in-site-navigation-to-discipline-page"
                layoutClassName={styles.subNavigationLink}
              >
                {x.title}
              </Link>
            </li>
          )
        )
      })}
    </ul>
  )
}
