import ReactDOM from 'react-dom'
import { useClientConfig } from '/machinery/ClientConfig'
import { useSpring, animated } from '@react-spring/web'
import { useBodyScrollLock } from '/machinery/useBodyScrollLock'
import { usePortalNode } from '/machinery/usePortalNode'
import FocusTrap from 'focus-trap-react'

import { Link } from '/features/buildingBlocks/Link'
import { CtaButtonPrimary } from '/features/buildingBlocks/CtaButton'
import { Icon } from '/features/buildingBlocks/Icon'

import iconLogo from '/images/icons/logo.raw.svg'
import iconCross from '/images/icons/cross.raw.svg'

import styles from './Portal.css'

export function Portal({ visible, children, onClose }) {
  const portalNode = usePortalNode(styles._rootComponent)
  const { ref: bodyScrollLockRef } = useBodyScrollLock({ enabled: visible })
  const config = useClientConfig()

  useEscape(onClose)

  const portalSpring = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 250 }
  })

  return portalNode && ReactDOM.createPortal(
    <FocusTrap>
      <animated.div style={portalSpring} className={styles.component} ref={bodyScrollLockRef} aria-hidden={visible}>
        <div className={styles.top}>
          <Link layoutClassName={styles.logo} dataX='link-to-home' href='/'>
            <Icon icon={iconLogo} />
          </Link>
          <CloseButton onClick={() => onClose()} layoutClassName={styles.button} />
        </div>
        <div className={styles.inner}>
          {children}
        </div>
        <div className={styles.bottom}>
          <CtaButtonPrimary href={config.careersLink.url} dataX='link-to-klm-careers'>
            {config.careersLink.title}
          </CtaButtonPrimary>
        </div>
      </animated.div>
    </FocusTrap>,
    portalNode
  )
}

function CloseButton({ onClick, layoutClassName }) {
  return (
    <button type='button' className={cx(styles.componentCloseButton, layoutClassName)} {...{ onClick }}>
      <Icon icon={iconCross} />
    </button>
  )
}

function useEscape(callback) {
  const callbackRef = React.useRef(null)
  callbackRef.current = callback

  React.useEffect(
    () => {
      window.addEventListener('keydown', handleKeyDown)
      return () => window.removeEventListener('keydown', handleKeyDown)

      function handleKeyDown(e) {
        if (['Esc', 'Escape'].includes(e.key)) {
          callbackRef.current()
        }
      }
    },
    []
  )
}
